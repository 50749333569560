var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity-speaker-block"},[_c('menu-breadcrumb',[_c('el-breadcrumb-item',{attrs:{"to":{ name: 'Activity' }}},[_vm._v("活動管理 ")]),_c('el-breadcrumb-item',[_vm._v("講者管理")])],1),_c('div',{staticClass:"container"},[_c('table-title',[_c('p',[_vm._v(" 講者列表 "),_c('span',{staticClass:"unit"},[_vm._v("數量："+_vm._s(_vm.total))])]),_c('div',[_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'CreateSpeaker' })}}},[_vm._v("新增講者 ")])],1)]),_c('el-table',{attrs:{"data":_vm.speaker_data}},[_c('el-table-column',{attrs:{"label":"ID","prop":"speaker_id","width":"80","sortable":""}}),_c('el-table-column',{attrs:{"label":"頭像","prop":"avatar_url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.avatar_url)?_c('el-avatar',{staticStyle:{"object-position":"center center"},attrs:{"size":60,"fit":"cover","src":_vm.handleAvatarUrl(row.avatar_url)}}):_c('el-avatar',{attrs:{"size":60,"fit":"contain"}},[_c('img',{staticStyle:{"transform":"scale(1.8)"},attrs:{"src":require("../../../assets/default-avatar.png"),"alt":"avatar"}})])]}}])}),_c('el-table-column',{attrs:{"label":"中文名稱","prop":"name_zh","sortable":""}}),_c('el-table-column',{attrs:{"label":"英文名稱","prop":"name_en","sortable":""}}),_c('el-table-column',{attrs:{"label":"創建時間","prop":"created_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$formatTime(row["created_at"]))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"更新時間","prop":"updated_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$formatTime(row["updated_at"]))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"編輯","fixed":"right","width":"180","sor":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"warning","icon":"el-icon-edit","size":"mini"},on:{"click":function($event){return _vm.$router.push({
                name: 'EditSpeaker',
                params: { id: row['speaker_id'] },
              })}}},[_vm._v("編輯")]),_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){return _vm.handleDelete(row['speaker_id'])}}},[_vm._v("刪除")])]}}])})],1),_c('table-pagination',{attrs:{"total":_vm.total,"limit":_vm.setup.limit,"page":_vm.setup.page},on:{"change-page":_vm.handleChangePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }