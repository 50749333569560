import request from "@/utils/request";

/**
 * Create speaker.
 *
 * @param {object} data
 * @param {File} data.avatar
 * @param {string} data.name_zh
 * @param {string} data.name_en
 * @param {string} data.introduction_zh
 * @param {string} data.introduction_en
 */
export function createSpeaker(data) {
  return request({
    url: "api/v1/activities-speakers",
    method: "POST",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

/**
 * Update speaker.
 *
 * @param speaker_id
 * @param {object} data
 * @param {File} [data.avatar]
 * @param {string} data.name_zh
 * @param {string} data.name_en
 * @param {string} data.introduction_zh
 * @param {string} data.introduction_en
 */
export function updateSpeaker(speaker_id, data) {
  return request({
    url: `api/v1/activities-speakers/${speaker_id}`,
    method: "PATCH",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

/**
 * Get speakers.
 */
export function getSpeakers() {
  return request({
    url: "api/v1/activities-speakers",
    method: "GET",
  });
}

/**
 * Get speakers with pagination.
 *
 * @param {object} [params]
 * @param {number} params.limit
 * @param {number} params.page
 */
export function getSpeakersPagination(params) {
  return request({
    url: "api/v1/activities-speakers/page",
    method: "GET",
    params,
  });
}

/**
 * Get speaker.
 *
 * @param {number} speaker_id
 */
export function getSpeaker(speaker_id) {
  return request({
    url: `api/v1/activities-speakers/${speaker_id}`,
    method: "GET",
  });
}

/**
 * Delete speaker.
 *
 * @param {number} speaker_id
 */
export function deleteSpeaker(speaker_id) {
  return request({
    url: `api/v1/activities-speakers/${speaker_id}`,
    method: "DELETE",
  });
}
