<template>
  <div class="activity-speaker-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Activity' }"
        >活動管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>講者管理</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          講者列表 <span class="unit">數量：{{ total }}</span>
        </p>

        <div>
          <el-button
            type="success"
            @click="$router.push({ name: 'CreateSpeaker' })"
            >新增講者
          </el-button>
        </div>
      </table-title>

      <el-table :data="speaker_data">
        <el-table-column
          label="ID"
          prop="speaker_id"
          width="80"
          sortable
        ></el-table-column>
        <el-table-column label="頭像" prop="avatar_url">
          <template v-slot="{ row }">
            <el-avatar
              v-if="row.avatar_url"
              :size="60"
              fit="cover"
              style="object-position: center center"
              :src="handleAvatarUrl(row.avatar_url)"
            >
            </el-avatar>
            <el-avatar v-else :size="60" fit="contain">
              <img
                src="../../../assets/default-avatar.png"
                alt="avatar"
                style="transform: scale(1.8)"
              />
            </el-avatar>
          </template>
        </el-table-column>
        <el-table-column
          label="中文名稱"
          prop="name_zh"
          sortable
        ></el-table-column>
        <el-table-column
          label="英文名稱"
          prop="name_en"
          sortable
        ></el-table-column>
        <el-table-column label="創建時間" prop="created_at" sortable>
          <template v-slot="{ row }">
            {{ $formatTime(row["created_at"]) }}
          </template>
        </el-table-column>
        <el-table-column label="更新時間" prop="updated_at" sortable>
          <template v-slot="{ row }">
            {{ $formatTime(row["updated_at"]) }}
          </template>
        </el-table-column>
        <el-table-column label="編輯" fixed="right" width="180" sor>
          <template v-slot="{ row }">
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="mini"
              @click="
                $router.push({
                  name: 'EditSpeaker',
                  params: { id: row['speaker_id'] },
                })
              "
              >編輯</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="handleDelete(row['speaker_id'])"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import { deleteSpeaker, getSpeakersPagination } from "@/api/activity-speaker";

export default {
  name: "Speaker",
  data() {
    return {
      speaker_data: [],
      total: 0,
      setup: {
        page: 0,
        limit: 25,
      },
    };
  },
  created() {
    if (this.$route.query.p) {
      this.setup.page = +this.$route.query.p;
    }
    this.handleGetSpeakers();
  },
  methods: {
    async handleGetSpeakers() {
      const { results, total } = await getSpeakersPagination(this.setup);
      this.speaker_data = results;
      this.total = total;
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetSpeakers();
    },
    handleDelete(speaker_id) {
      this.$confirm("是否刪除該講者", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteSpeaker(speaker_id);
          await this.handleGetSpeakers();

          this.$message({
            type: "success",
            message: "刪除成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
    handleAvatarUrl(url) {
      return `${process.env.VUE_APP_IMAGE_API}/${url}`;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-avatar {
  img {
    width: 100%;
  }
}
</style>
